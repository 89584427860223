const EntitiesMenu = [
  {
    heading: 'menu',
    route: '/entities',
    pages: [

      {
        heading: 'Entity Types',
        route: '/entities/entity-types',
        fontIcon: 'bi-dashboard',
        svgIcon: '/media/icons/duotune/general/gen027.svg',
      },
      {
        heading: 'Add Employee',
        route: '/entity-employee/1',
        fontIcon: 'bi-dashboard',
        svgIcon: '/media/icons/duotune/general/gen027.svg',
      },

    ],
  },
];

export default EntitiesMenu;
